import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../components/breadcrumb"
import EventList from "../components/event-list"
import Layout from "../layouts/default"
import PageTitle from "../components/page-title"
import Paragraph from "../components/paragraph"
import Seo from "../components/seo"
import Stack from "../components/stack"

const VeranstaltungenPage = () => {
  const data = useStaticQuery(graphql`
    query VeranstaltungenIndexQuery {
      ogImage: file(relativePath: { eq: "og_images/aus-dem-archiv.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Veranstaltungen"
        image={data.ogImage.childImageSharp.gatsbyImageData}
        description="Alle kommenden Veranstaltungen des Hölderlinturms im Überblick."
      />
      <Stack>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Veranstaltungen",
                link: "/veranstaltungen",
              },
            ]}
          />
          <PageTitle>Veranstaltungen</PageTitle>
          <Paragraph>
            Aus Platzgründen ist bei einigen Veranstaltungen eine Anmeldung erforderlich.
          </Paragraph>
        </Stack>

        <EventList headingElement="h2" />
      </Stack>
    </Layout>
  )
}

export default VeranstaltungenPage
